@import "./styleFunctions/mixins";

main {
  @include flexContainer(
    $alignItems: flex-start,
    $justifyContent: flex-start,
    $gap: 0
  );
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  header,
  footer {
    width: 100%;
  }
  > div {
    flex: 1 1 auto;
    width: 100%;
  }
}

.disabledBtn {
  pointer-events: none;
  background-color: var(--ternaryColor) !important;
  border: none !important;
} 
