@import "../../../styleFunctions/mixins";

.controllerContainer {
    @include flexContainer;
    button, input {
        width: 100%;
        padding-block: 0.5rem;
    }
    input {
        text-align: center;
        font-size: 1.8rem;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 9px;
    }
    button {
        background-color: var(--accentColor);
        color: var(--secondaryColor);
        border: 0.2rem solid var(--accentColor);
        font-size: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: transparent;
            color: var(--accentColor);
        }
    }
}