@import "../../styleFunctions/mixins";

.homePage {
  background-color: var(--primaryColor);
  padding-top: 7rem;
  // display: flex;
  // align-items: center;
  position: relative;
  .pageContent {
    margin-top: 8rem;
    position: relative;
    > div {
      padding: 0 2rem 10rem;
    }
    .sectionTitle {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .sectionSubTitle {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 2.5rem;
      margin: 0;
    }
    .sectionInnerTitle {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 2rem;
      margin: 0;
    }
    .beveragesContainer {
      margin-top: 3rem;
    }
    .innerCategoryBox {
      margin-top: 3rem;
      &:nth-of-type(1) {
        margin-top: 2rem;
      }
    }
    .foodMenuList {
      @include flexContainer($gap: 0px);
      flex-direction: column;
      margin-top: 1rem;
    }
    .foodMenuContainer {
      @include flexContainer($gap: 40px);
      margin-top: 3rem;
      flex-wrap: wrap;
      > div {
        width: 100%;
        flex: 1 0 300px;
        max-width: 500px;
      }
    }
    .successContainer {
      @include flexContainer;
      flex-direction: column;
      > div {
        width: 100%;
      }
      .successGif {
        text-align: center;
        img {
          width: 100%;
          max-width: 400px;
        }
      }
      p {
        font-size: 2.2rem;
        margin: -80px 0 0;
      }
    }
  }
  .cartContent {
    margin-block: 3rem;
    @include flexContainer($gap: 30px);
    flex-direction: column;
    .totalAmountContainer {
      @include flexContainer($justifyContent: space-between);
      background-color: var(--ternaryColor);
      padding: 1.5rem 2rem;
      filter: drop-shadow(2px 12px 6px rgb(0, 0, 0, 0.2));
      position: relative;
      width: 100%;
      h2,
      p {
        margin: 0;
      }
      h2 {
        font-size: 2.2rem;
        line-height: 3.2rem;
      }
    }
  }
  .cartBox {
    position: sticky;
    top: 0;
  }
}

@media only screen and (max-width: 590px) {
  .homePage {
    padding-inline: 2rem;
    .pageContent {
      .sectionTitle {
        justify-content: center;
        font-size: 2.6rem;
      }
      .sectionSubTitle {
        justify-content: center;
      }
    }
  }
}
