@import "../../../styleFunctions/mixins";

.cartItemContainer {
    @include flexContainer;
    flex-direction: column;
    background-color: var(--ternaryColor);
    padding: 2rem 1.5rem;
    filter: drop-shadow(2px 12px 6px rgb(0,0,0,0.2));
    position: relative;
    width: 100%;
    > div {
        width: 100%;
        @include flexContainer($justifyContent: space-between);
        // > div {
        //     width: 100%;
        // }
    } 
    p {
        margin: 0;
    }
    button {
        background-color: var(--accentColor);
        border: none;
        outline: none;
        color: var(--secondaryColor);
        border-radius: 50%;
        font-size: 3rem;
        width: 35px;
        aspect-ratio: 1;
        position: absolute;
        right: -1.5rem;
        top: -1.5rem;
        @include flexContainer;
        svg {
            transform: rotate(45deg);
        }
    }
}