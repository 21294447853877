.footerContainer {
    padding-block: 3rem;
    border-top: 0.2rem solid var(--accentColor);
    > div {
        align-items: center;
    }
    img {
        max-width: 10rem;
    }
    p {
        margin: 0;
        text-align: right;
        a {
            color: var(--accentColor) !important;
        }
    }
}

@media only screen and (max-width: 590px) {
    .footerContainer {
        > div {
            > div {
                text-align: center;
                p {
                    text-align: center;
                    margin-top: 2rem;
                }
            }
        }
    }
}