.productContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--ternaryColor);
  padding: 2rem 1.5rem;
  filter: drop-shadow(2px 12px 6px rgb(0, 0, 0, 0.2));
  width: 100%;
  row-gap: 2rem;
  > div {
    flex-grow: 1;
    flex-basis: 240px;
    &:nth-of-type(3) {
        flex-basis: 150px;
    }
    &:nth-of-type(2) {
      flex-basis: 10px;
  }
  }
  .productInfo {
    h3 {
      font-size: 2rem;
      line-height: 3rem;
      margin: 0;
      color: var(--secondaryColor);
    }
    p {
      font-size: 1.4rem;
      margin: 0;
      color: var(--secondaryColor);
    }
  }
  .productPrice {
    p {
      margin: 0;
      font-size: 2rem;
      text-align: center;
    }
  }
  .addCartBlock {
    display: flex;
    align-items: center;
    gap: 1rem;
    .addToCartBtn {
      button {
        background-color: var(--accentColor);
        color: var(--secondaryColor);
        border: 0.2rem solid var(--accentColor);
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
        padding: 1rem;
        &:hover {
          background-color: transparent;
          color: var(--accentColor);
        }
      }
    }
  }
}
