.box {
    width: 100%;
    height: auto;
    float: left;
    transition: .5s linear;
    position: relative;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    overflow: hidden;
    padding: 15px;
    text-align: center;
    margin: 0 5px;
    background: transparent;
    text-transform: uppercase;
    font-weight: 900;
  }
  
  .box:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    height: 4px;
    width: 100%;
    border-bottom: 4px solid #000;
    border-left: 4px solid #000;
    box-sizing: border-box;
    transform: translateX(100%);
  }
  
  .box:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-top: 4px solid #000;
    border-right: 4px solid #000;
    box-sizing: border-box;
    transform: translateX(-100%);
  }
  
  .box:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  .box:hover:before {
    border-color: var(--accentColor);
    height: 100%;
    transform: translateX(0);
    transition: .3s transform linear, .3s height linear .3s;
  }
  
  .box:hover:after {
    border-color: var(--accentColor);
    height: 100%;
    transform: translateX(0);
    transition: .3s transform linear, .3s height linear .5s;
  }
  
  .skaBtn {
    color: black;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    max-width: 30rem;
    font-size: 2.6rem;
    letter-spacing: 2px;
    &:hover {
        color: var(--accentColor);
    }
  }

@media only screen and (max-width: 990px) {
  .skaBtn {
    .box {
      &:after, &:before {
        border-color: var(--accentColor) !important;
        transform: translateX(0%);
        height: 100%;
      }
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
    }
  }
}