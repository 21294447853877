@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

:root {
  --primaryColor: #4b4f54;
  --secondaryColor: #fff;
  --ternaryColor: #6f7276;
  --accentColor: #bd9b60;
  --fontFamily: "Montserrat";
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
  color: inherit !important;
  font-size: inherit;
}

img {
  width: 100%;
}

ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

body {
  position: relative;
  &::-webkit-scrollbar {
    width: 7px; /* Set the width of the scrollbar */
    background-color: transparent !important; /* Set the background color of the scrollbar */
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar-track {
    background: var(--primaryColor);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px var(--accentColor);
  }
}
