.customerForm {
  > div {
    width: 100%;
    input {
      width: 100%;
      padding: 1.5rem;
      border: 2px solid var(--accentColor);
      background-color: transparent;
      color: var(--secondaryColor);
      font-size: 1.8rem;
      margin-top: 0.5rem;
      &::placeholder {
        color: inherit;
      }
      &:focus {
        outline: none;
      }
    }
    .errorText {
      margin-top: 0.5rem;
    }
  }
  button {
    background-color: var(--accentColor);
    border: none;
    width: 100%;
    font-size: 2rem;
    color: var(--secondaryColor);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border: 0.2rem solid var(--accentColor);
    transition: all 0.3s ease-in-out;
    padding-block: 1rem;
    &:hover {
      background-color: transparent;
      color: var(--accentColor);
    }
  }
}
