@import "../../../styleFunctions/mixins";

.productCard {
  @include flexContainer($gap: 2rem);
  flex-direction: column;
  background-color: var(--ternaryColor);
  padding: 3rem;
  filter: drop-shadow(2px 12px 6px rgb(0,0,0,0.2));
  > div {
    width: 100%;
  }
  .productInfo {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1rem;
    p {
        font-size: 2rem;
    }
    // .productName {
    //     color: var(--accentColor);
    // }
    &::before {
      content: "";
      width: 90%;
      height: 0.2rem;
      background-color: var(--accentColor);
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .addToCartContainer {
    button {
        background-color: var(--accentColor);
        border: none;
        width: 100%;
        font-size: 2rem;
        color: var(--secondaryColor);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        border: 0.2rem solid var(--accentColor);
        transition: all 0.3s ease-in-out;
        padding-block: 1rem;
        &:hover {
            background-color: transparent;
            color: var(--accentColor);
        }
    }
  }
}
